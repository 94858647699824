import { Config } from '../../interfaces/config.interface';
import Logo from './logo.jpeg';

const config: Config = {
  name: 'Sellin',
  title: 'Actividades de voluntariado',
  logo: Logo,
  logo_width: '120px',
  catalogTalents: true,
};

export default config;
